import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { fileService } from "../services";
import { getFileExtensionFromFileName } from "../utils/strings";

export async function confirmModelArtifactUpload(modelId: string, artifactId: string) {
  await fileService.post(`model/${modelId}/artifact/${artifactId}/confirm_upload`);
}

async function informModelArtifactUploadFailed(modelId: string, artifactId: string) {
  await fileService.post(`model/${modelId}/artifact/${artifactId}/failed_upload`);
}

export const useUpload = () =>
  useMutation(async (file: File) => {
    const getUploadURLPayload = {
      name: file.name,
      base_artifact: {
        name: file.name,
        artifact_extension: getFileExtensionFromFileName(file.name),
      },
    };
    const fileUploadArtifact = await fileService.post("/model/", getUploadURLPayload);

    if (fileUploadArtifact.base_artifact) {
      return axios
        .put(fileUploadArtifact.base_artifact?.signed_upload_url, file, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        })
        .then(async () => {
          await confirmModelArtifactUpload(
            fileUploadArtifact.base_artifact?.model_id,
            fileUploadArtifact.base_artifact?.id,
          );
          return fileUploadArtifact;
        })
        .catch(async () => {
          await informModelArtifactUploadFailed(
            fileUploadArtifact.base_artifact?.model_id,
            fileUploadArtifact.base_artifact?.id,
          );
          return { ...fileUploadArtifact, errorToS3: true };
        });
    }
    return fileUploadArtifact;
  });
