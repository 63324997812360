export enum AcceptableFileExtensions {
  BMP = "bmp",
  CATPART = "catpart",
  CATPRODUCT = "catproduct",
  CSV = "csv",
  DOCX = "docx",
  FIG = "fig",
  GIF = "gif",
  HTM = "htm",
  HTML = "html",
  JPEG = "jpeg",
  JPG = "jpg",
  JSON = "json",
  M = "m",
  MAT = "mat",
  MDXML = "mdxml",
  MDZIP = "mdzip",
  OBJ = "obj",
  PDF = "pdf",
  PNG = "png",
  PRT = "prt",
  STEP = "step",
  STL = "stl",
  STP = "stp",
  TXT = "txt",
  XLST = "xlst",
  XLSX = "xlsx",
  XML = "xml",
  ZIP = "zip",
}
