import { useMutation, useQuery } from "@tanstack/react-query";

import { profileService } from "../services";

interface UserFormData {
  firstName: string;
  lastName: string;
  displayName: string;
}

interface UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

export const userProfileKeys = {
  all: ["selfprofile"],
  item: (id: number | string | null) => [...userProfileKeys.all, id],
};

export const useGetUserProfile = () =>
  useQuery(userProfileKeys.all, () => profileService.get("/users/me/profile"), { refetchOnWindowFocus: false });

export const useUpdateUserNames = () =>
  useMutation((data: UserFormData) => profileService.put("/users/me/profile", data));

export const useUpdatePassword = () =>
  useMutation((data: UpdatePasswordPayload) => profileService.put("/users/me/password", data));

export const useMyMemberships = () =>
  useQuery(userProfileKeys.item(null), () => profileService.post("/memberships/me/_search"), {
    refetchOnWindowFocus: false,
  });
