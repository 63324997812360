import { Menu, MenuItem, MenuProps, styled, SvgIcon, SxProps, Theme, Typography } from "@mui/material";
import React, { useMemo } from "react";

import { customColors } from "../../lib/utils/colors";

type Sizes = "h-24" | "h-32" | "h-40" | "h-48" | "h-60";
type SizesRecords = Record<Sizes, string>;
const LABEL_VARIANTS = {
  "h-24": "subtitle4",
  "h-32": "subtitle3",
  "h-40": "subtitle3",
  "h-48": "subtitle2",
  "h-60": "subtitle1",
} as const satisfies SizesRecords;

const ICON_SIZES = {
  "h-24": "1rem",
  "h-32": "1.25rem",
  "h-40": "1.25rem",
  "h-48": "1.5rem",
  "h-60": "1.5rem",
} satisfies SizesRecords;

interface StyledMenuProps extends MenuProps {}
// TO DO: CHANGE MENU COMPONENT TO MUI POPPER COMPONENT FOR BETTER USER EXP.
const StyledMenu = styled((props: StyledMenuProps) => (
  <Menu
    disablePortal
    elevation={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "4px",
    marginTop: "2px",
    minWidth: "180px",
    backgroundColor: customColors.white[0],
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "4px 0",
    },

    "& .MuiMenuItem-root": {
      minHeight: "auto",
      padding: "0 8px",
      columnGap: "6px",
      alignItems: "center",
      transition: "backgroundColor 0.25s",

      "& .MuiSvgIcon-root": {
        color: customColors.gray[400],
      },

      "&:hover": {
        backgroundColor: customColors.gray[50],
      },

      "&:active": {
        backgroundColor: customColors.primary[50],
        color: customColors.primary[400],

        "& .MuiSvgIcon-root": {
          color: customColors.primary[400],
        },
      },
    },
  },
}));

type Item = {
  name: string;
  label: string;
  icon?: React.ElementType | null;
};
export interface SubmenuProps {
  open: boolean;
  selectedItem?: string;
  items: Array<Item>;
  clickHandler: (_event: React.MouseEvent<HTMLElement>, _name: string) => void;
  handleClose: (_event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: Element | null;
  specialOptionProps?: {
    label: string;
    icon?: React.ElementType | null;
    onClick: (_event: React.MouseEvent<HTMLElement>) => void;
  };
  size?: Sizes;
  sx?: SxProps<Theme>;
}
const Submenu: React.FC<SubmenuProps> = ({
  items,
  open,
  selectedItem,
  clickHandler,
  anchorEl,
  handleClose,
  specialOptionProps,
  size = "h-32",
  sx = [],
}) => {
  const menuItems = useMemo(
    () =>
      items?.map((item) => (
        <MenuItem
          key={`item-${item.name}`}
          onClick={(event) => {
            clickHandler(event, item.name);
            handleClose(event);
          }}
          selected={selectedItem ? selectedItem === item.name : undefined}
          disableRipple
          sx={{
            height: `${size.split("-")[1]}px`,
            color: customColors.gray[800],
          }}
        >
          {item.icon && (
            <SvgIcon
              component={item.icon}
              inheritViewBox
              sx={{ fontSize: ICON_SIZES[size], margin: "4px", fill: "none" }}
            />
          )}
          <Typography variant={LABEL_VARIANTS[size]} sx={{ color: "inherit", textTransform: "capitalize" }}>
            {item.label}
          </Typography>
        </MenuItem>
      )),
    [items, selectedItem, size, clickHandler, handleClose],
  );
  return (
    <StyledMenu
      id="demo-customized-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={[{}, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {menuItems}
      {specialOptionProps && (
        <MenuItem
          onClick={(event) => {
            handleClose(event);
            specialOptionProps?.onClick(event);
          }}
          disableRipple
          sx={{
            height: `${size.split("-")[1]}px`,
            color: customColors.gray[800],
            borderTop: `1px solid ${customColors.gray[100]}`,
            pt: "4px",
          }}
        >
          {specialOptionProps?.icon && (
            <SvgIcon
              component={specialOptionProps?.icon}
              inheritViewBox
              sx={{ fontSize: ICON_SIZES[size], margin: "4px", fill: "none" }}
            />
          )}
          <Typography variant={LABEL_VARIANTS[size]} sx={{ color: "inherit", textTransform: "capitalize" }}>
            {specialOptionProps?.label}
          </Typography>
        </MenuItem>
      )}
    </StyledMenu>
  );
};

export default Submenu;
