/**
 * Convert path like /index/:id to /index
 *
 * @param {string} route
 * @returns {string}
 */
const removeReactRoutesParamFromPath = (route: string) => {
  let cleanRoute = route;
  const firstParamIdx = route.indexOf("/:");

  if (firstParamIdx > -1) {
    cleanRoute = route.slice(0, firstParamIdx);
  }

  return cleanRoute;
};

/**
 * slice pathname to array of sub-paths
 *
 * @param {string} pathname path to slice to array
 * @returns {Array<string>}
 */
const splitPathname = (pathname: string) => {
  const start = pathname[0] === "/" ? 1 : 0;
  return pathname.slice(start).split("/");
};

/**
 * Get all current search params in key value pairs
 * @returns {Object}
 */
const getAllSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {}; // Add index signature

  searchParams.forEach(([key, value]) => {
    params[key] = value;
  });

  return params;
};

/**
 * append newParams on the current searchParams
 *
 * @param {Object} newParams key value
 * @returns object of new appended search params
 */
const appendOnSearchParams = (newParams: [key: string]) => ({
  ...getAllSearchParams(),
  ...newParams,
});

// Export All helpers method
export { removeReactRoutesParamFromPath, splitPathname, getAllSearchParams, appendOnSearchParams };
