import { CircularProgress } from "@mui/material";
import React, { createContext, useMemo } from "react";

import { useMyMemberships } from "../../external-api/profile";
import { checkIsCustomerAdmin } from "../../permissions";

export const UserMembershipContext = createContext({ isCustomerAdmin: false });

function UserMembershipProvider({ children }: { children: React.ReactNode }) {
  const { data: membershipData, isLoading } = useMyMemberships();

  const isCustomerAdmin = useMemo(() => checkIsCustomerAdmin(membershipData), [membershipData]);

  const contextValue = useMemo(
    () => ({
      isCustomerAdmin: Boolean(isCustomerAdmin),
    }),
    [isCustomerAdmin],
  );

  if (isLoading) {
    return <CircularProgress />;
  }
  return <UserMembershipContext.Provider value={contextValue}>{children}</UserMembershipContext.Provider>;
}

export default UserMembershipProvider;
