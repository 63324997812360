import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Grid, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FileError, GenericFile } from "../../assets/svg";
import { customColors } from "../../lib/utils/colors";

interface MessageWithIconProps {
  color: string;
  icon: React.ElementType;
  message: string;
}
const MessageWithIcon: React.FC<MessageWithIconProps> = ({ color, icon, message }) => (
  <Stack direction="row" columnGap="8px" alignItems="center" sx={{ color }}>
    <Typography variant="subtitle3b" sx={{ color: "inherit", whiteSpace: "nowrap" }}>
      {message}
    </Typography>

    <SvgIcon component={icon} inheritViewBox sx={{ color: "inherit", fontSize: "1.5rem" }} />
  </Stack>
);

export interface FileStatusRowProps {
  name: string;
  errorMsg?: string;
}

const FileStatusRow = (props: FileStatusRowProps) => {
  const { name, errorMsg } = props;
  const { t } = useTranslation();

  /**
   * render the progress based on the current status
   */
  const renderUploadStatus = useMemo(() => {
    if (!errorMsg)
      return (
        <MessageWithIcon color={customColors.secondary[400]} message={t("common.uploaded")} icon={CheckCircleIcon} />
      );

    return (
      <MessageWithIcon color={customColors.red[300]} message={t("upload.statusRow.uploadingError")} icon={ErrorIcon} />
    );
  }, [errorMsg, t]);

  /**
   * render the icon based on the current status
   */
  const renderFileIcon = useMemo(() => {
    if (errorMsg)
      return (
        <SvgIcon
          component={FileError}
          inheritViewBox
          sx={{
            color: customColors.red[300],
          }}
        />
      );

    return <SvgIcon component={GenericFile} inheritViewBox />;
  }, [errorMsg]);

  /**
   * render the row
   */
  return (
    <Stack
      rowGap="4px"
      sx={{
        flexGrow: 1,
        px: "16px",
        py: "10px",
        bgcolor: customColors.white[0],
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        columnGap="8px"
        maxWidth="100%"
        sx={{ width: "auto" }}
      >
        <Grid item component={Stack} sx={{ flexDirection: "row", columnGap: "8px", alignItems: "center" }} xs>
          {/* Icon */}
          {renderFileIcon}

          <Stack rowGap="4px" sx={{ width: "calc(100% - 48px)" }}>
            {/* File Name */}
            <Tooltip
              title={
                <Typography variant="subtitle4" sx={{ fontSize: "0.625rem" }}>
                  {name}
                </Typography>
              }
              arrow
              placement="top-start"
              disableHoverListener={name?.length <= 27}
              PopperProps={{ sx: { zIndex: 99999 } }}
            >
              <Typography
                variant="subtitle3b"
                noWrap
                sx={{
                  color: customColors.gray[800],
                  overflow: "hidden",
                  maxWidth: 250,
                }}
              >
                {name}
              </Typography>
            </Tooltip>
          </Stack>
        </Grid>

        <Grid
          data-testid="fileStatusRow_item-status"
          item
          component={Stack}
          sx={{
            flexDirection: "row",
            columnGap: "8px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {/* file uploaded or not */}
          {renderUploadStatus}
        </Grid>
      </Grid>
      {errorMsg && (
        <Typography
          variant="subtitle4"
          sx={{
            color: customColors.red[300],
            overflowWrap: "break-word",
          }}
        >
          {errorMsg}
        </Typography>
      )}
    </Stack>
  );
};

export default FileStatusRow;
