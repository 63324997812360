import { Box, Typography } from "@mui/material";
import React from "react";

const TopBanner = ({ text = "CUI data allowed; ITAR data allowed" }: { text?: string }) => (
  <Box
    sx={{
      padding: "2px",
      position: "fixed",
      top: 0,
      width: "100%",
      height: "29px",
      backgroundColor: "#723D9A",
      color: "#fff",
      textAlign: "center",
      zIndex: 2,
    }}
  >
    <Typography variant="body3">{text}</Typography>
  </Box>
);

export default TopBanner;
