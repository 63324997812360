import React from "react";
import { toast } from "react-hot-toast";

import IstariToast, { ToastTypes } from "./IstariToast";

export const showToast = (message: string, type: ToastTypes) => {
  toast.custom((tos) => <IstariToast message={message} type={type} clearToast={() => toast.remove(tos.id)} />, {
    position: "bottom-right",
  });
};
