import { Paper, Stack, SvgIcon, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import errorScream from "../../assets/images/error_scream.jpg";
import { IstariLogoColorLarge } from "../../assets/svg";
import { customColors } from "../../lib/utils/colors";
import IstariLink from "../IstariLink";

function ErrorBoundaryDisplay() {
  const { t } = useTranslation();

  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "#f3f3f3", width: "100%", height: "100vh", display: "flex", justifyContent: "center" }}
    >
      <Stack rowGap="60px" alignItems="center" mt="48px" maxWidth="sm">
        <SvgIcon component={IstariLogoColorLarge} inheritViewBox sx={{ width: "auto", height: "auto" }} />
        <Stack rowGap="16px" alignItems="center" px="60px">
          <img alt="Istari error" src={errorScream} width="440px" height="auto" />
          <Typography variant="h4" sx={{ color: customColors.gray[800] }}>
            {t("errorBoundary.title")}
          </Typography>

          <Typography variant="subtitle2" sx={{ color: customColors.gray[600], textAlign: "center" }}>
            {t("errorBoundary.msg")}
          </Typography>
        </Stack>

        {/* IstariLink href or to does not work with route when error occurs page is shown */}
        <a href="/">
          <IstariLink label={t("common.backToHomePage")} />
        </a>
      </Stack>
    </Paper>
  );
}

export default ErrorBoundaryDisplay;
