import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Collapse, Paper, Slide, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { customColors } from "../../lib/utils/colors";
import IstariIconButton from "../IstariIconButton";

interface FilesStatusCardProps {
  children: React.ReactNode;
  show: boolean;
  title: string;
  totalFilesCount: number;
  onDismiss: () => void;
}
const FilesStatusCard: React.FC<FilesStatusCardProps> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  /**
   * Render the card
   */
  return (
    <Slide data-testid="file-upload-status-card-container" in={props.show} direction="up">
      <Paper
        elevation={4}
        sx={{
          borderRadius: "8px",
          bgcolor: customColors.white[0],
          p: 0,
          width: 540,
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 9999,
        }}
      >
        {/* ----------- Header ----------- */}
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={isCollapsed ? "12px" : "16px"}>
          <Stack direction="row" alignItems="center" columnGap="8px">
            {/* ----- Title ---- */}
            <Typography variant="h6" sx={{ color: customColors.gray[800] }}>
              {props.title} ({props.totalFilesCount})
            </Typography>
          </Stack>

          {/* ----- Action Buttons ----- */}
          <Stack direction="row" columnGap="16px">
            {/* Collapse button */}
            <IstariIconButton
              title="Collapse"
              Icon={ExpandMoreIcon}
              color="gray"
              type="square"
              variant="white"
              size={24}
              onClick={() => setIsCollapsed((prev) => !prev)}
              sx={[
                {
                  fontSize: "1.25rem",
                  transition: "transform 0.25s",
                  "&:hover": { bgcolor: "transparent" },
                },
                isCollapsed && { transform: "rotate(180deg)" },
              ]}
            />

            {/* Dismiss button */}
            <IstariIconButton
              title="Close"
              Icon={ClearIcon}
              color="gray"
              type="square"
              variant="white"
              size={24}
              onClick={props.onDismiss}
              sx={{
                fontSize: "1.25rem",
                "&:hover": { bgcolor: "transparent" },
              }}
            />
          </Stack>
        </Stack>

        {/* ---------- Files status rows ---------- */}
        <Collapse
          in={!isCollapsed}
          timeout={250}
          sx={{
            // for max 5 files status rows to be shown
            maxHeight: 320,
            overflow: "auto",
            borderBottomRightRadius: "8px",
            borderBottomLeftRadius: "8px",

            "& .MuiCollapse-wrapperInner": {
              mb: "8px",
            },
          }}
        >
          {props.children}
        </Collapse>
      </Paper>
    </Slide>
  );
};

export default FilesStatusCard;
