import React, { createContext, useCallback, useMemo, useState } from "react";

import { showToast } from "../../components/IstariToast/showToast";
import { FileUploadStatusType } from "../../enums";
import { generateUniqueId } from "../helpers/general-helper";

// List of initial state
export const initialState = {
  files: new Map(),
  showStatusCard: false,
  addFiles: (_newFiles: File[], _category: string) => {},
  updateFile: (
    _fileId: string,
    _newData: { data?: { progress: string }; status: string; errorMsg: string | undefined },
  ) => {},
  removeFile: (_fileId: string) => {},
  setShowStatusCard: (_show: boolean) => {},
};

export const FilesStatusContext = createContext(initialState);

function FilesStatusProvider({ children }: { children: React.ReactNode }) {
  const [files, setFiles] = useState(new Map());
  const [showStatusCard, setShowStatusCard] = useState(false);

  /**
   * Add files to the files list after attaching a unique id to each file
   */
  const addFiles = useCallback((newFiles: File[], category: string) => {
    // create a new list (2D array) of files with unique id as the first element (map keys)
    const newFilesList = newFiles?.map((file) => [
      generateUniqueId(),
      { binary: file, status: FileUploadStatusType.New, category },
    ]);

    // add the new files to the files map local state
    setFiles((prevFiles) => new Map([...prevFiles, ...newFilesList] as any));

    // show the status card
    setShowStatusCard(true);
  }, []);

  /**
   * Update one file of the files map state
   */
  const updateFile = useCallback((fileId: string, newData: { status: string; errorMsg: string | undefined }) => {
    setFiles((prevFiles) => {
      // create a clone of the map state
      const newFiles = new Map(prevFiles);
      // update the file data
      newFiles.set(fileId, { ...newFiles.get(fileId), ...newData });
      // return the new map state
      return newFiles;
    });
  }, []);

  /**
   * Remove one file from the files map state
   */
  const removeFile = useCallback(
    (fileId: string) => {
      setFiles((prevFiles) => {
        const newFiles = new Map(prevFiles);
        newFiles.delete(fileId);
        return newFiles;
      });
    },
    [setFiles],
  );

  /**
   * Get the file from the files map state by id
   */
  const getFile = useCallback((fileId: string) => files.get(fileId), [files]);

  const contextValue = useMemo(
    () => ({
      files,
      getFile,
      addFiles,
      updateFile,
      removeFile,
      showStatusCard,
      setShowStatusCard,
      showToast,
    }),
    [addFiles, files, removeFile, showStatusCard, showToast, updateFile, getFile],
  );

  return <FilesStatusContext.Provider value={contextValue}>{children}</FilesStatusContext.Provider>;
}

export default FilesStatusProvider;
