import { CircularProgress, IconButton, IconButtonProps, SvgIcon } from "@mui/material";
import { CSSProperties } from "@mui/styled-engine";
import { styled } from "@mui/system";
import React, { useMemo } from "react";

import { customColors } from "../../lib/utils/colors";

const EXCLUDED_PROPS = ["size", "variant", "color", "type"] as const;
const VARIANTS = ["colored", "white", "outlined", "lightBg"] as const;
const TYPES = ["round", "square"] as const;
const COLORS = ["primary", "secondary", "gray"] as const;
const SIZES = [60, 48, 40, 32, 24] as const;

const FONTSIZE = {
  60: "1.5rem",
  48: "1.5rem",
  40: "1.25rem",
  32: "1rem",
  24: "1rem",
} as const;

const BUTTON_STYLES = ["normal", "hover", "pressed", "disabled"];
type ButtonStyles = Record<(typeof BUTTON_STYLES)[number], CSSProperties>;

interface StyledIconButtonProps extends IconButtonProps {
  "button-size"?: number;
  "border-radius"?: string;
  "button-styles"?: ButtonStyles;
  fontsize?: string;
}

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => !EXCLUDED_PROPS.includes(prop as never),
})((props: StyledIconButtonProps) => ({
  height: `${props["button-size"]}px`,
  width: `${props["button-size"]}px`,
  borderRadius: props["border-radius"],
  fontSize: props.fontsize,
  ...props["button-styles"]?.normal,
  "&:hover": {
    ...props["button-styles"]?.hover,
  },
  "&.Mui-disabled": {
    ...props["button-styles"]?.disabled,
    opacity: "0.4",
  },
  "&:active": {
    ...props["button-styles"]?.pressed,
  },
}));

type ExcludedProps = (typeof EXCLUDED_PROPS)[number];
interface IstariIconButtonProps extends Omit<IconButtonProps, ExcludedProps> {
  Icon: React.ElementType;
  variant?: (typeof VARIANTS)[number];
  type?: (typeof TYPES)[number];
  borderRadius?: number;
  size?: (typeof SIZES)[number];
  color?: (typeof COLORS)[number];
  loading?: boolean;
  href?: string;
}
const IstariIconButton: React.FC<IstariIconButtonProps> = ({
  Icon,
  variant = "colored",
  type = "round",
  borderRadius = 4,
  size = 40,
  color = "primary",
  sx = [],
  loading = false,
  ...others
}) => {
  const styles = useMemo(() => {
    if (["primary", "secondary"].includes(color)) {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[color][600],
            color: customColors.white[0],
          },
          hover: {
            backgroundColor: customColors[color][500],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[color][400],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[color][400],
            color: customColors.white[0],
            opacity: 0.4,
          },
        };
      }
      if (variant === "lightBg") {
        return {
          pressed: {
            backgroundColor: customColors[color][100],
            color: customColors[color][500],
          },
          hover: {
            backgroundColor: customColors[color][50],
            color: customColors[color][400],
          },
          normal: {
            backgroundColor: customColors[color][100],
            color: customColors[color][400],
          },
          disabled: {
            backgroundColor: customColors[color][100],
            color: customColors[color][400],
            opacity: 0.4,
          },
        };
      }
      if (variant === "white") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors[color][500],
          },
          hover: {
            backgroundColor: customColors[color][50],
            color: customColors[color][400],
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[color][400],
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors[color][400],
            opacity: 0.4,
          },
        };
      }
      if (variant === "outlined") {
        return {
          pressed: {
            backgroundColor: customColors[color][50],
            color: customColors[color][600],
            border: `2px solid ${customColors[color][600]}`,
          },
          hover: {
            backgroundColor: customColors[color][50],
            color: customColors[color][500],
            border: `2px solid ${customColors[color][500]}`,
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[color][400],
            border: `2px solid ${customColors[color][400]}`,
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors[color][400],
            border: `2px solid ${customColors[color][400]}`,
            opacity: 0.4,
          },
        };
      }
    }
    // ----------------------------------------------------------------
    if (color === "gray") {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[color][950],
            color: customColors.white[0],
          },
          hover: {
            backgroundColor: customColors[color][800],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[color][900],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[color][400],
            color: customColors.white[0],
          },
        };
      }
      if (variant === "lightBg") {
        return {
          pressed: {
            backgroundColor: customColors[color][100],
            color: customColors.gray[950],
          },
          hover: {
            backgroundColor: customColors[color][50],
            color: customColors.gray[800],
          },
          normal: {
            backgroundColor: customColors[color][100],
            color: customColors.gray[800],
          },
          disabled: {
            backgroundColor: customColors[color][200],
            color: customColors.gray[400],
          },
        };
      }
      if (variant === "white") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors[color][950],
          },
          hover: {
            backgroundColor: customColors[color][100],
            color: customColors[color][800],
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[color][800],
          },
          disabled: {
            backgroundColor: customColors[color][200],
            color: customColors.gray[400],
          },
        };
      }
      if (variant === "outlined") {
        return {
          pressed: {
            backgroundColor: customColors[color][50],
            color: customColors.gray[950],
            border: `2px solid ${customColors[color][400]}`,
          },
          hover: {
            backgroundColor: customColors[color][50],
            color: customColors.gray[900],
            border: `2px solid ${customColors[color][800]}`,
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `2px solid ${customColors[color][800]}`,
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `2px solid ${customColors[color][800]}`,
            opacity: 0.4,
          },
        };
      }
    }
    return null;
  }, [color, variant]);

  return (
    <StyledIconButton
      border-radius={type === "round" ? "50%" : `${borderRadius}px`}
      button-size={size}
      button-styles={styles}
      disableRipple
      {...others}
      sx={[
        {
          fontSize: FONTSIZE[size],
          p: 0,
          transition: "background-color 0.25s",
          fill: "currentcolor",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {loading ? (
        <CircularProgress
          size={FONTSIZE[size]}
          sx={{
            color: "inherit",
          }}
        />
      ) : (
        <SvgIcon component={Icon} fontSize="inherit" inheritViewBox sx={{ fill: "inherit", color: "inherit" }} />
      )}
    </StyledIconButton>
  );
};

export default IstariIconButton;
