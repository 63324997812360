import { removeReactRoutesParamFromPath } from "./url-helper";

/**
 *  Used for share paths in component to prevent write or redirect path static in code
 */
export const FULL_ROUTES_PATH = {
  ROOT: "/", // Root path

  // Main
  UPLOAD: "upload",
  FILE: "file",
  WRAPPER: "wrapper",
  SHARED: "shared",
  ACCESS_DENIED: "access-denied",
  NOT_FOUND: "404",
  SEARCH: "search",
  SETTINGS: "settings",
  ADMIN_PANEL: "admin-panel",
  DOCUMENTATION: "documentation",
  ALL_MODELS: "all-models",
  MAGIC_DOCS: "magic-docs",
  ALL_MAGIC_DOCS: "all-magic-docs",

  // Authentication
  SIGN_IN: "sign-in",
};

const constructPlainRoutes = (fullRoutes: { [key: string]: any }) => {
  const planRoutes: { [key: string]: any } = {};

  Object.keys(fullRoutes).forEach((featureKey: string) => {
    planRoutes[featureKey] = {};

    Object.keys(fullRoutes[featureKey]).forEach((pageKey) => {
      planRoutes[featureKey][pageKey] = removeReactRoutesParamFromPath(fullRoutes[featureKey][pageKey]);
    });
  });

  return planRoutes;
};

/**
 * @return FULL_ROUTES_PATH
 * TODO: put the full path with the params in the FULL_ROUTES_PATH, so we can use it directly in the components routes
 * and use this export to construct any routes in all other places.
 */
export const ROUTES_PATH = constructPlainRoutes(FULL_ROUTES_PATH);
