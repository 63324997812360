import { Box, CircularProgress, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";

import { IstariLogoColorLarge } from "../../assets/svg";
import IstariButton from "../../components/IstariButton";
import { customColors } from "../../lib/utils/colors";

const FullScreenLoading = () => (
  <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <CircularProgress size={50} />
  </Box>
);

interface AuthErrorBoundaryProps {
  children: React.ReactNode;
}

function AuthErrorBoundary({ children }: AuthErrorBoundaryProps) {
  const [hasAuthenticationErrors, setHasAuthenticationErrors] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    if (auth && auth.isAuthenticated) {
      window.localStorage.setItem("istari-user", JSON.stringify(auth.user));
    }
    if (auth && auth.error) {
      setHasAuthenticationErrors(true);
    }
  }, [auth, auth.isAuthenticated]);

  switch (auth.activeNavigator) {
    case "signinSilent":
    case "signoutRedirect":
      return <FullScreenLoading />;
    default:
  }

  if (auth.isLoading) {
    return <FullScreenLoading />;
  }

  if (hasAuthenticationErrors) {
    return (
      <Paper
        elevation={0}
        sx={{ backgroundColor: "#f3f3f3", width: "100%", height: "100vh", display: "flex", justifyContent: "center" }}
      >
        <Stack rowGap="60px" alignItems="center" mt="48px" maxWidth="sm">
          <SvgIcon component={IstariLogoColorLarge} inheritViewBox sx={{ width: "auto", height: "auto" }} />
          <Stack rowGap="16px" alignItems="center" px="60px">
            <Typography variant="h4" sx={{ color: customColors.gray[800] }}>
              You are signed out.
            </Typography>

            <Typography variant="subtitle2" sx={{ color: customColors.gray[600], textAlign: "center" }}>
              Please click the button to log in again.
            </Typography>
          </Stack>

          <IstariButton fullWidth color="gray" variant="lightBg" size="h-60" onClick={() => auth.signinRedirect()}>
            Login
          </IstariButton>
        </Stack>
      </Paper>
    );
  }

  return children;
}

export default AuthErrorBoundary;
