import { Button, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";

import { DiamondIcon } from "../../../../assets/svg";
import { FilesStatusContext } from "../../../../lib/contexts/FilesStatusContext";
import { customColors } from "../../../../lib/utils/colors";

interface FilesStatusButtonProps {}
const FilesStatusButton: React.FC<FilesStatusButtonProps> = () => {
  const filesCtx = useContext(FilesStatusContext);

  /**
   * toggle the status card
   */
  const buttonClickHandler = useCallback(() => {
    filesCtx.setShowStatusCard(!filesCtx.showStatusCard);
  }, [filesCtx]);

  return (
    <Tooltip title="Files upload status">
      {/*
        MUI: You are providing a disabled `button` child to the Tooltip component.
        A disabled element does not fire events.
        Tooltip needs to listen to the child element's events to display the title.
      */}

      <span>
        <Button
          aria-label="Files upload status"
          onClick={buttonClickHandler}
          // disable the button if there are no files
          disabled={!filesCtx.files?.size}
          sx={{
            position: "relative",
            borderRadius: "4px",
            bgcolor: customColors.white[0],
            minWidth: "auto",
            p: 0,
            width: "40px",
            height: "40px",
            "&:hover": {
              "&, .progressContainer": {
                bgcolor: customColors.gray[100],
              },

              "& .countContainer": {
                outlineColor: customColors.gray[100],
              },
            },

            "&.Mui-disabled": {
              ".MuiSvgIcon-root": {
                color: customColors.gray[400],
              },
            },

            "&, .progressContainer, .countContainer": {
              transition: "all 0.25s ease-in-out",
            },
          }}
        >
          {/* Diamond Icon */}
          <SvgIcon component={DiamondIcon} inheritViewBox sx={{ color: customColors.gray[800], fontSize: "1.25rem" }} />

          {/* Status count chip */}
          {/* DON'T SHOW COUNT & PROGRESS INDICATORS IF NO FILES RECENTLY OR CURRENTLY UPLOADING/PROCESSING */}
          {!!filesCtx.files?.size && (
            <Stack
              className="countContainer"
              sx={{
                bgcolor: customColors.gray[400],
                borderRadius: "40px",
                p: "2px 5px",
                outline: `2px solid ${customColors.white[0]}`,
                position: "absolute",
                right: "-10px",
                top: "3px",
              }}
            >
              <Typography variant="caption" sx={{ color: customColors.white[0] }}>
                {filesCtx.files?.size}
              </Typography>
            </Stack>
          )}
        </Button>
      </span>
    </Tooltip>
  );
};

export default FilesStatusButton;
