export function getFileExtensionFromFileName(fileName: string | undefined): string {
  return !fileName || fileName.lastIndexOf(".") === -1 ? "" : fileName.substring(fileName.lastIndexOf(".") + 1);
}

export function replaceAllSpaces(string: string): string {
  return !string ? "" : string.replace(/\s/g, "");
}

export function hyphenLowercase(string: string): string {
  return !string ? "" : string.replace(/\s/g, "-").toLowerCase();
}

export function stringifyObjectValue(obj: object) {
  return Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, val?.toString()]));
}

export function validateEmail(string: string): boolean {
  return Boolean(
    string.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line max-len
    ),
  );
}

export function trimLowercase(str: string) {
  if (typeof str === "string") return str.split(" ").join("").toLocaleLowerCase();

  return "";
}

export function truncate(str = "", maxLength = 45) {
  if (str?.length > maxLength) {
    return `${str.slice(0, maxLength)}...`;
  }

  return str;
}
