import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { AuthProvider } from "react-oidc-context";
import { Outlet } from "react-router-dom";

import "./App.css";
import { authConfig } from "./auth/zitadel-config";
import FilesStatusProvider from "./lib/contexts/FilesStatusContext";
import GlobalProvider from "./lib/contexts/GlobalContext";
import AuthErrorBoundary from "./pages/Authentication/AuthErrorBoundary";
import FilesStatusFloatingCard from "./pages/Main/Upload/partials/FilesStatusFloatingCard";

function App() {
  return (
    <>
      <CssBaseline />

      <GlobalProvider>
        <AuthProvider {...authConfig}>
          <AuthErrorBoundary>
            <FilesStatusProvider>
              <Outlet />
              <FilesStatusFloatingCard />
            </FilesStatusProvider>
          </AuthErrorBoundary>
        </AuthProvider>
      </GlobalProvider>
    </>
  );
}

export default App;
