export type QueryKeys = {
  all: string[];
  item: (_id: string, ..._more: string[]) => Array<string>;
  list: (_filter: unknown) => Array<string | unknown>;
};

/**
 * Wrappers query keys
 */
const wrappersQueryKeys: QueryKeys = {
  all: ["wrappers"],
  item: (id) => [...wrappersQueryKeys.all, id],
  list: (filter) => [...wrappersQueryKeys.all, "list", filter],
};

/**
 * Execution query keys
 */
const executionQueryKeys: QueryKeys = {
  all: ["execution"],
  item: (exeId, wrapperId) => [...executionQueryKeys.all, exeId, wrapperId],
  list: (filter) => [...executionQueryKeys.all, "list", filter],
};

/**
 * Users query keys
 */
const usersQueryKeys: QueryKeys = {
  all: ["users"],
  item: (id) => [...usersQueryKeys.all, id],
  list: (filter) => [...usersQueryKeys.all, "list", filter],
};

/**
 * Users query keys
 */
const functionsQueryKeys: QueryKeys = {
  all: ["functions"],
  item: (id) => [...functionsQueryKeys.all, id],
  list: (filter) => [...functionsQueryKeys.all, "list", filter],
};

/**
 * Variations query keys
 */
const variationsQueryKeys: QueryKeys = {
  all: ["variations"],
  item: (variationId) => [...variationsQueryKeys.all, variationId],
  list: (filter) => [...variationsQueryKeys.all, "list", filter],
};

/**
 * Users query keys
 */
const loggerQueryKeys: {
  listUser: (_fileId: string, _wrapperId: string) => string[];
} & QueryKeys = {
  all: ["logger"],
  item: (id) => [...loggerQueryKeys.all, id],
  list: (filter) => [...loggerQueryKeys.all, "list", filter],
  listUser: (fileId, wrapperId) => [...loggerQueryKeys.all, "list", fileId, wrapperId],
};

/**
 * Magic Docs query keys
 */
const magicDocsQueryKeys: QueryKeys = {
  all: ["magic_docs"],
  item: (docId) => [...magicDocsQueryKeys.all, docId],
  list: (filter) => [...magicDocsQueryKeys.all, "list", filter],
};

const commentsQueryKeys: {
  listComments: (artifactId: string, pageNumber: number) => (string | number)[];
} & QueryKeys = {
  all: ["comments"],
  item: (commentId) => [...commentsQueryKeys.all, commentId],
  list: (filter) => [...commentsQueryKeys.all, "list", filter],
  listComments: (artifactId, pageNumber) => [...commentsQueryKeys.all, "list", artifactId, pageNumber],
};

const modelApprovalQueryKeys: QueryKeys = {
  all: ["model_approval"],
  item: (id) => [...modelApprovalQueryKeys.all, id],
  list: (filter) => [...modelApprovalQueryKeys.all, "list", filter],
};

export {
  wrappersQueryKeys,
  executionQueryKeys,
  usersQueryKeys,
  variationsQueryKeys,
  functionsQueryKeys,
  loggerQueryKeys,
  magicDocsQueryKeys,
  commentsQueryKeys,
  modelApprovalQueryKeys,
};
