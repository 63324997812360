import { Button, ButtonProps, CircularProgress, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useMemo } from "react";
import { Plus } from "react-feather";

import { customColors, CustomColorsType } from "../../lib/utils/colors";

const TYPO_VARIANTS = {
  "h-60": "subtitle2b",
  "h-48": "subtitle2b",
  "h-40": "subtitle3b",
  "h-32": "subtitle4b",
  "h-24": "subtitle4",
} as const;

const PADDING_X = {
  "h-60": "20px",
  "h-48": "16px",
  "h-40": "12px",
  "h-32": "12px",
  "h-24": "8px",
} as const;

const COLUMN_GAP = {
  "h-60": "12px",
  "h-48": "8px",
  "h-40": "8px",
  "h-32": "8px",
  "h-24": "4px",
} as const;

const ICON_SIZE = {
  "h-60": "24px",
  "h-48": "24px",
  "h-40": "20px",
  "h-32": "16px",
  "h-24": "16px",
} as const;

const SPINNER_SIZE = {
  "h-60": 24,
  "h-48": 24,
  "h-40": 20,
  "h-32": 16,
  "h-24": 16,
} as const;

const BORDER_SIZE = {
  "h-60": "2px",
  "h-48": "2px",
  "h-40": "2px",
  "h-32": "1px",
  "h-24": "1px",
} as const;

const BUTTON_VARIANTS = ["colored", "lightBg", "white", "outlined"] as const;
const BUTTON_SIZES = ["h-60", "h-48", "h-40", "h-32", "h-24"] as const;
const EXCLUDED_PROPS = ["size", "variant", "color", "startIcon", "endIcon"] as const;

type ButtonStyles = Record<"normal" | "hover" | "pressed" | "disabled", React.CSSProperties>;

interface StyledButtonProps extends ButtonProps {
  btnheight: string;
  btnstyles: ButtonStyles | null;
}

const StyledButton = styled(Button, {
  // TO PREVENT FORWARDING THESE PROPS TO THE MUI BUTTON COMPONENT
  shouldForwardProp: (prop) => !EXCLUDED_PROPS.includes(prop as never),
})((props: StyledButtonProps) => ({
  height: `${props.btnheight.split("-")[1]}px`,
  columnGap: "12px",
  minWidth: "60px",
  alignItems: "center",
  ...props.btnstyles?.normal,
  "&:hover": {
    ...props.btnstyles?.hover,
  },
  "&.Mui-disabled": {
    ...props.btnstyles?.disabled,
    opacity: "0.4",
  },
  "&:active": {
    ...props.btnstyles?.pressed,
  },
  "& .MuiButton-iconSizeMedium": {
    margin: 0,
  },
}));

type ExcludedProps = (typeof EXCLUDED_PROPS)[number];

interface IstariButtonProps extends Omit<ButtonProps, ExcludedProps> {
  size?: (typeof BUTTON_SIZES)[number];
  variant?: (typeof BUTTON_VARIANTS)[number];
  loading?: boolean;
  loadingMessage?: string;
  color?: keyof CustomColorsType;
  startIcon?: React.ElementType;
  endIcon?: React.ElementType;
}

const IstariButton: React.FC<IstariButtonProps> = ({
  size = "h-40",
  color = "primary",
  variant = "colored",
  sx = [],
  children,
  loading,
  startIcon,
  endIcon,
  loadingMessage,
  ...others
}) => {
  const styles = useMemo(() => {
    const colorKey = color;
    const btnSize = size;

    if (["red", "green", "blue", "violet"].includes(colorKey)) {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][400],
            color: customColors[colorKey][100],
          },
          hover: {
            backgroundColor: customColors[colorKey][300],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
            opacity: 0.4,
          },
        };
      }
      // IF VARIANT IS NOT COLORED
      return {
        pressed: {
          backgroundColor: customColors[colorKey][100],
          color: customColors[colorKey][500],
          border: variant === "outlined" ? `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][500]}` : "none",
        },
        hover: {
          backgroundColor: variant === "lightBg" ? customColors.white[0] : customColors[colorKey][100],
          color: customColors[colorKey][400],
          border: variant === "outlined" ? `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}` : "none",
        },
        normal: {
          backgroundColor: variant === "lightBg" ? customColors[colorKey][100] : customColors.white[0],
          color: customColors[colorKey][400],
          border: variant === "outlined" ? `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}` : "none",
        },
        disabled: {
          backgroundColor: variant === "lightBg" ? customColors[colorKey][100] : customColors.white[0],
          color: customColors[colorKey][400],
          border: variant === "outlined" ? `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}` : "none",
          opacity: 0.4,
        },
      };
    }
    // ----------------------------------------------------------------
    if (["primary", "secondary"].includes(colorKey)) {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][600],
            color: customColors.white[0],
          },
          hover: {
            backgroundColor: customColors[colorKey][500],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
            opacity: 0.4,
          },
        };
      }
      if (variant === "lightBg") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][100],
            color: customColors[colorKey][500],
          },
          hover: {
            backgroundColor: customColors[colorKey][50],
            color: customColors[colorKey][400],
          },
          normal: {
            backgroundColor: customColors[colorKey][100],
            color: customColors[colorKey][400],
          },
          disabled: {
            backgroundColor: customColors[colorKey][100],
            color: customColors[colorKey][400],
            opacity: 0.4,
          },
        };
      }
      if (variant === "white") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][500],
          },
          hover: {
            backgroundColor: customColors[colorKey][50],
            color: customColors[colorKey][400],
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
            opacity: 0.4,
          },
        };
      }
      if (variant === "outlined") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][50],
            color: customColors[colorKey][600],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][600]}`,
          },
          hover: {
            backgroundColor: customColors[colorKey][50],
            color: customColors[colorKey][500],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][500]}`,
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
            opacity: 0.4,
          },
        };
      }
    }
    // ----------------------------------------------------------------
    if (color === "gray") {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][950],
            color: customColors.white[0],
          },
          hover: {
            backgroundColor: customColors[colorKey][800],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[colorKey][900],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
          },
        };
      }
      if (variant === "lightBg") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[950],
          },
          hover: {
            backgroundColor: customColors[colorKey][50],
            color: customColors.gray[800],
          },
          normal: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[800],
          },
          disabled: {
            backgroundColor: customColors[colorKey][200],
            color: customColors.gray[400],
          },
        };
      }
      if (variant === "white") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][950],
          },
          hover: {
            backgroundColor: customColors[colorKey][100],
            color: customColors[colorKey][800],
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][800],
          },
          disabled: {
            backgroundColor: customColors[colorKey][200],
            color: customColors.gray[400],
          },
        };
      }
      if (variant === "outlined") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
          },
          hover: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[700],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][300]}`,
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][300]}`,
          },
          disabled: {
            backgroundColor: customColors[colorKey][200],
            color: customColors.gray[400],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][300]}`,
          },
        };
      }
    }
    // ----------------------------------------------------------------
    if (color === "yellow") {
      if (variant === "colored") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][400],
            color: customColors[colorKey][100],
          },
          hover: {
            backgroundColor: customColors[colorKey][300],
            color: customColors.white[0],
          },
          normal: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
          },
          disabled: {
            backgroundColor: customColors[colorKey][400],
            color: customColors.white[0],
            opacity: 0.4,
          },
        };
      }
      if (variant === "lightBg") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[950],
          },
          hover: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
          },
          normal: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[800],
          },
          disabled: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[800],
            opacity: 0.4,
          },
        };
      }
      if (variant === "white") {
        return {
          pressed: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][500],
          },
          hover: {
            backgroundColor: customColors[colorKey][100],
            color: customColors[colorKey][500],
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors[colorKey][400],
            opacity: 0.4,
          },
        };
      }
      if (variant === "outlined") {
        return {
          pressed: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[950],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][500]}`,
          },
          hover: {
            backgroundColor: customColors[colorKey][100],
            color: customColors.gray[800],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
          },
          normal: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
          },
          disabled: {
            backgroundColor: customColors.white[0],
            color: customColors.gray[800],
            border: `${BORDER_SIZE[btnSize]} solid ${customColors[colorKey][400]}`,
            opacity: 0.4,
          },
        };
      }
    }
    return null;
  }, [color, variant, size]);

  /**
   * Adornments (startIcon, endIcon & loading spinner)
   */
  const adornments = useMemo(() => {
    let start = null;
    if (startIcon) {
      start = (
        <SvgIcon
          component={startIcon}
          inheritViewBox
          sx={{
            fontSize: ICON_SIZE[size],
            fill: "inherit",
            color: "inherit",
          }}
        />
      );
    }

    let end = null;
    if (loading) {
      end = (
        <CircularProgress
          size={SPINNER_SIZE[size]}
          sx={{
            color: "inherit",
          }}
        />
      );
    } else if (endIcon) {
      end = (
        <SvgIcon
          component={endIcon || Plus}
          inheritViewBox
          sx={{
            fontSize: ICON_SIZE[size as keyof typeof ICON_SIZE],
            fill: "inherit",
            color: "inherit",
            visibility: loading ? "hidden" : "visible",
          }}
        />
      );
    }

    return { start, end };
  }, [startIcon, size, loading, endIcon]);

  return (
    <StyledButton
      btnheight={size}
      btnstyles={styles}
      {...others}
      sx={[
        {
          paddingX: PADDING_X[size],
          columnGap: COLUMN_GAP[size],
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {/* START ADORNMENT */}
      {adornments.start}

      {/* BUTTON'S LABEL */}
      {loading ? (
        // IF IS LOADING SHOW LOADING MESSAGE INSTEAD OF THE BUTTON'S LABEL (CHILDREN) IF EXISTS
        loadingMessage && <Typography variant={TYPO_VARIANTS[size]}>{loadingMessage}</Typography>
      ) : (
        // IF IS NOT LOADING DON'T SHOW THE LABEL
        <Typography variant={TYPO_VARIANTS[size]}>{children}</Typography>
      )}
      {/* END ADORNMENT */}
      {adornments.end}
    </StyledButton>
  );
};

export default IstariButton;
