import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal as MuiModal, Stack, Typography } from "@mui/material";
import React, { memo } from "react";

import IstariButton from "../IstariButton";

interface ModalProps {
  actionButtonDataTestId?: string;
  actionButtonDisabled?: boolean;
  actionButtonLabel: string;
  actionButtonLoading: boolean;
  actionButtonType?: string;
  actionHandler: () => void;
  content: React.ReactNode;
  open: boolean;
  title: string;
  onCancel?: () => void;
  onHide: () => void;
}

const Modal = memo(
  ({
    actionButtonDataTestId,
    actionButtonDisabled,
    actionButtonLabel,
    actionButtonLoading,
    actionButtonType,
    actionHandler,
    content,
    open = false,
    title,
    onCancel,
    onHide,
  }: ModalProps) => {
    const hasCancel = Boolean(onCancel);

    if (!open) {
      return null;
    }

    return (
      <MuiModal open={open} onClose={onHide} disablePortal={false}>
        <Stack
          sx={{
            backgroundColor: "#fff",
            width: "600px",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "50%",
          }}
        >
          <Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 20px 5px 20px",
                borderBottom: "1px solid #eee",
              }}
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: 600 }}>{title}</Typography>
              <IconButton disableTouchRipple disableFocusRipple title="close modal" onClick={onHide}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack sx={{ padding: "20px" }}>{content}</Stack>
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                borderTop: "1px solid #eee",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ display: "flex", columnGap: "10px", alignItems: "center", justifyContent: "space-between" }}>
                {hasCancel && (
                  <IstariButton
                    data-testid="modal-cancel-button"
                    disableFocusRipple
                    disableTouchRipple
                    color="gray"
                    fullWidth
                    size="h-40"
                    variant="lightBg"
                    sx={{}}
                    startIcon={() => null}
                    endIcon={() => null}
                    loadingMessage=""
                    onClick={onCancel}
                  >
                    Cancel
                  </IstariButton>
                )}
                <IstariButton
                  disableFocusRipple
                  disableTouchRipple
                  color={actionButtonType === "danger" ? "red" : "primary"}
                  data-testid={actionButtonDataTestId}
                  disabled={actionButtonDisabled}
                  fullWidth
                  size="h-40"
                  variant="colored"
                  sx={{}}
                  startIcon={() => null}
                  endIcon={() => null}
                  loading={actionButtonLoading}
                  loadingMessage=""
                  onClick={actionHandler}
                >
                  {actionButtonLabel}
                </IstariButton>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </MuiModal>
    );
  },
);

export default Modal;
