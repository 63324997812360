import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import locales from "./locales";

// Local storage key
export const LANGUAGE_STORAGE_KEY = "istari-current-Lang";

export const setLang = (language: string) => {
  localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
};

/**
 * Get Current Language and set default language if not exsits
 *
 * @returns {string}
 */
export const getLang = () => {
  let currentLang = localStorage.getItem(LANGUAGE_STORAGE_KEY);

  // If current language is empty, then get default language and set it
  if (!currentLang) {
    currentLang = "en";
    setLang(currentLang);
  }

  return currentLang;
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: getLang(),
    resources: locales,
    fallbackLng: getLang(),
    // make sure to return undefined if the key is not found
    parseMissingKeyHandler: () => undefined,
    interpolation: {
      escapeValue: false,
    },
  });

/**
 * Method used to validate if current language is set in i18n...
 *
 * @param lang
 * @returns {boolean}
 */
export function checkI18nLanguage(lang: string) {
  return i18n.language === lang;
}

export default i18n;
