import { Stack, SvgIcon, SxProps, Theme } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { IstariLogoColor } from "../../assets/svg";

interface LinkLogoProps {
  fontSize?: string;
  sx?: SxProps<Theme>;
  to?: string;
}
const LinkLogo: React.FC<LinkLogoProps> = ({ fontSize = "1rem", ...props }) => (
  <Stack sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}>
    {props.to ? (
      <Link aria-label="Istari Digital Home" to={props.to} style={{ lineHeight: "16px" }}>
        <SvgIcon
          data-testid="logo-icon"
          component={IstariLogoColor}
          inheritViewBox
          sx={{ width: "auto", height: "auto", fontSize }}
        />
      </Link>
    ) : (
      <SvgIcon
        data-testid="logo-icon"
        component={IstariLogoColor}
        inheritViewBox
        sx={{ width: "auto", height: "auto", fontSize }}
      />
    )}
  </Stack>
);

export default LinkLogo;
