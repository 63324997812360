import { ThemeProvider } from "@mui/material";
import React, { createContext, ReactNode, ReducerState, useMemo, useReducer } from "react";
import { ToastBar, Toaster } from "react-hot-toast";

import { theme } from "../utils/create-theme";

// List of initial state for all global data
export const initialState = {};

interface ReducerActionType {
  type: string;
}

// Shared Reducer For Global Context
const Reducer = (state: ReducerState<any>, action: ReducerActionType) => {
  switch (action.type) {
    default:
      return state;
  }
};

// Create Global Context
export const GlobalContext = createContext(initialState);

// Global State Which its provide context for children
function GlobalProvider({
  children,
  defaultInitialState = {},
}: {
  children: ReactNode;
  defaultInitialState?: Record<string, any>;
}) {
  const initState = { ...initialState, ...defaultInitialState };
  const [state] = useReducer(Reducer, initState);

  const contextValue = useMemo(
    () => ({
      globalState: state,
    }),
    [state],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>
        {children}

        <Toaster toastOptions={{ duration: 5000 }}>
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                ...t.style,
                animation: t.visible ? "custom-enter 1s ease" : "custom-exit 1s ease",
              }}
            />
          )}
        </Toaster>
      </ThemeProvider>
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
