import axios, { AxiosPromise } from "axios";

import AuthenticationStore from "../auth";
import { authConfig } from "../auth/zitadel-config";

function getNewAccessToken(refreshToken: string) {
  return axios.post(
    `${authConfig.authority}/oauth/v2/token`,
    {
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      client_id: `${authConfig.client_id}`,
    },
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    },
  );
}

let refreshPromise: AxiosPromise | undefined;

export const refreshAccessToken = async () => {
  try {
    const AS = AuthenticationStore();
    const refreshToken = AS.getRefreshToken();

    refreshPromise = refreshPromise || getNewAccessToken(refreshToken);
    const response = await refreshPromise;

    const accessToken = response.data;

    return AS.saveAccessToken(accessToken);
  } catch (error) {
    return error;
  }
};
