import { Box, CircularProgress, Container, SxProps, Theme } from "@mui/material";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { getEnv } from "../../../utils/get_env";
import TopBanner from "../../TopBanner";
import { TOP_HEADER_HEIGHT } from "../constants";
import Header from "../Header";

interface FullPageWidthLayoutProps {
  sx?: SxProps<Theme>;
  paddingBottom?: string;
}

export const FullPageWidthLayout: React.FC<FullPageWidthLayoutProps> = ({ paddingBottom = "0px", ...props }) => (
  <Box component="main" sx={{ minHeight: "100vh" }}>
    {getEnv("VITE_ITAR") === "true" && <TopBanner />}
    <Header withLogo />

    <Container
      disableGutters
      maxWidth={false}
      sx={[
        {
          pb: paddingBottom,
          pt: `${TOP_HEADER_HEIGHT}px`,
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // Print media query
          "@media print": {
            pt: "0",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx || {}]),
      ]}
    >
      <Suspense fallback={<CircularProgress />}>
        <Outlet />
      </Suspense>
    </Container>
  </Box>
);

export default FullPageWidthLayout;
