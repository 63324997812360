import { ZitadelRoles } from "../enums";

interface MembershipList {
  roles: string[];
}

interface Membership {
  result?: MembershipList[];
}

export const checkIsCustomerAdmin = (data: Membership | undefined) =>
  data && data.result && data.result[0]?.roles?.includes(ZitadelRoles.ADMIN);

export const accessOptions = [
  {
    label: "owner",
    name: "owner",
  },
  {
    label: "editor",
    name: "editor",
  },
  {
    label: "approver",
    name: "approver",
  },
  {
    label: "viewer",
    name: "viewer",
  },
];
