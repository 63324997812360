import SuccessIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import AlertIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/HighlightOff";
import InfoIcon from "@mui/icons-material/Info";
import { Box, CircularProgress, IconButton, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import React, { ElementType, useEffect, useState } from "react";

import { ToastType } from "../../enums";
import { customColors } from "../../lib/utils/colors";

const ICONS: { [key: string]: ElementType } = {
  error: ErrorIcon,
  success: SuccessIcon,
  alert: AlertIcon,
  info: InfoIcon,
};

const BG_COLORS = {
  error: customColors.red[300],
  success: customColors.secondary[400],
  alert: customColors.yellow[400],
  info: customColors.primary[400],
  dark: customColors.gray[900],
  light: customColors.white[0],
};

const COLOR = {
  error: customColors.white[0],
  success: customColors.white[0],
  alert: customColors.gray[800],
  info: customColors.white[0],
  dark: customColors.white[0],
  light: customColors.gray[800],
};

export type ToastTypes = keyof typeof ToastType;
interface IstariToastProps {
  message: string;
  subMessage?: string;
  type?: ToastTypes;
  clearToast?: () => void;
}
const IstariToast: React.FC<IstariToastProps> = ({ message, subMessage, type = "dark", clearToast }) => {
  const [progress, setProgress] = useState(-100);

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 0) {
        setProgress((prevProgress) => prevProgress + 1);
      }
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  return (
    <Paper
      data-testid="istari-toast-container"
      elevation={3}
      sx={{
        px: "16px",
        py: "12px",
        bgcolor: BG_COLORS[type],
        borderRadius: "4px",
        maxWidth: "480px",
        color: COLOR[type],
      }}
    >
      <Stack direction="row" alignItems="center" columnGap="8px">
        {ICONS[type] && (
          <SvgIcon
            component={ICONS[type]}
            sx={{
              color: "inherit",
              fontSize: "1.5rem",
              alignSelf: "flex-start",
            }}
          />
        )}

        <Stack>
          <Typography
            variant="body2"
            sx={{
              color: "inherit",
              whiteSpace: "pre-line",
            }}
          >
            {message}
          </Typography>

          {!!subMessage && (
            <Typography variant="subtitle4" sx={{ color: "inherit" }}>
              {subMessage}
            </Typography>
          )}
        </Stack>

        <Box sx={{ position: "relative", ml: "12px" }}>
          <CircularProgress
            variant="determinate"
            value={progress}
            thickness={2}
            size={28}
            sx={{
              position: "absolute",
              color: "inherit",
            }}
          />
          <IconButton
            data-testid="istari-toast-clear-button"
            disableRipple
            sx={{
              bgcolor:
                type === (ToastType.light || ToastType.alert) ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.2)",
              color: "inherit",
              fontSize: "1.125rem",
              padding: 0,
              width: "28px",
              height: "28px",
            }}
            onClick={clearToast}
          >
            <ClearIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  );
};

export default IstariToast;
