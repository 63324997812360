import { createTheme, Theme } from "@mui/material/styles";

import { FontType } from "../../enums";
import { customColors } from "./colors";

export const theme: Theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1240,
      xl: 1536,
    },
  },
  typography: {
    allVariants: {
      fontFamily: FontType.SOURCE_SANS_PRO,
      fontWeight: 400,
    },
    // --------------- HEADERS ---------------
    h1: {
      fontFamily: FontType.INTER,
      fontSize: "3.75rem",
      fontWeight: 700,
      lineHeight: "100%",
    },
    h2: {
      fontFamily: FontType.INTER,
      fontSize: "3rem",
      fontWeight: 700,
      lineHeight: "108%",
    },
    h3: {
      fontFamily: FontType.INTER,
      fontSize: "2.5rem",
      fontWeight: 700,
      lineHeight: "120%",
    },
    h4: {
      fontFamily: FontType.INTER,
      fontSize: "2rem",
      fontWeight: 700,
      lineHeight: "125%",
    },
    h5: {
      fontFamily: FontType.INTER,
      fontSize: "1.5rem",
      fontWeight: 700,
      lineHeight: "117%",
    },
    h6: {
      fontFamily: FontType.INTER,
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: "138%",
    },
    // --------------- BODIES ---------------
    body1: {
      fontSize: "1.125rem",
      lineHeight: "156%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "150%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    body3: {
      fontSize: "0.875rem",
      lineHeight: "143%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    body4: {
      fontSize: "0.75rem",
      lineHeight: "150%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    // --------------- SUBTITLES ---------------
    subtitle1: {
      fontSize: "1.125rem",
      lineHeight: "156%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle1b: {
      fontSize: "1.125rem",
      lineHeight: "156%",
      fontWeight: 600,
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle2: {
      fontSize: "1rem",
      lineHeight: "150%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle2b: {
      fontSize: "1rem",
      lineHeight: "150%",
      fontWeight: 600,
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle3: {
      fontSize: "0.875rem",
      lineHeight: "143%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle3b: {
      fontSize: "0.875rem",
      lineHeight: "143%",
      fontWeight: 600,
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle4: {
      fontSize: "0.75rem",
      lineHeight: "1rem",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    subtitle4b: {
      fontSize: "0.75rem",
      lineHeight: "133%",
      fontWeight: 600,
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    // --------------- Additional text elements ---------------
    caption: {
      fontSize: "0.75rem",
      lineHeight: "100%",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
    overline: {
      fontSize: "0.75rem",
      lineHeight: "100%",
      letterSpacing: "0.08em",
      textTransform: "uppercase",
      fontFamily: FontType.SOURCE_SANS_PRO,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          letterSpacing: "normal",
          textTransform: "none",
          borderRadius: "4px",
          ":hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontWeight: 600,
          fontFamily: FontType.SOURCE_SANS_PRO,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: "1.125rem",
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",

            "&.Mui-disabled": {
              backgroundColor: customColors.gray[100],
              "& fieldset, &:hover fieldset": {
                border: `1px solid ${customColors.gray[200]}`,
              },

              "& .MuiInputBase-input": {
                color: customColors.gray[400],
              },
            },

            "& fieldset": {
              border: `1px solid ${customColors.gray[200]}`,
            },
            "&:hover fieldset": {
              border: `1px solid ${customColors.gray[300]}`,
            },
            "&.Mui-focused fieldset": {
              border: `1px solid ${customColors.gray[300]}`,
            },
          },

          "& .MuiInputBase-root": {
            "&.Mui-focused fieldset": {
              borderColor: customColors.gray[300],
            },

            "&.MuiInputBase-adornedStart": {
              paddingLeft: "16px",
            },

            "&.MuiInputBase-adornedEnd": {
              paddingRight: "16px",
            },
          },

          "& .MuiInputLabel-root": {
            fontWeight: 600,
            color: customColors.gray[500],
          },

          "& .MuiInputBase-input": {
            padding: "14px 14px  16px 16px",
            color: customColors.gray[900],

            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },

            "&.MuiInputBase-inputAdornedEnd": {
              paddingRight: 0,
            },

            "&::placeholder": {
              color: customColors.gray[300],
              fontSize: "1.125rem",
              lineHeight: "156%",
              fontFamily: FontType.SOURCE_SANS_PRO,
              fontWeight: 400,
              opacity: 1,
            },
          },
          "& .MuiFormHelperText-root": {
            fontSize: "0.75rem",
            lineHeight: "1rem",
            fontFamily: FontType.SOURCE_SANS_PRO,
            color: customColors.gray[400],
          },
        },
      },
    },

    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
          transform: "scale(1, 0.75)",
          backgroundColor: customColors.gray[200],
        },
      },
    },
  },
  palette: {
    background: {
      default: customColors.white[0],
    },
    primary: {
      light: customColors.primary[100],
      main: customColors.primary[400],
      dark: customColors.primary[500],
      contrastText: customColors.white[0],
    },
    secondary: {
      light: customColors.secondary[100],
      main: customColors.secondary[400],
      dark: customColors.secondary[500],
      contrastText: customColors.white[0],
    },
    error: {
      main: customColors.red[400],
      light: customColors.red[100],
      dark: customColors.red[500],
    },

    textGray: {
      light: customColors.gray[100],
      main: customColors.gray[200],
      dark: customColors.gray[400],
      contrastText: customColors.gray[900],
    },
    action: {
      // disabledBackground: customColors.gray[50],
      disabled: customColors.gray[300],
    },
    info: {
      light: customColors.blue[100],
      main: customColors.blue[300],
      dark: customColors.blue[500],
      contrastText: customColors.white[0],
    },
  },
  shadows: [
    "none",
    "0px 2px 4px rgba(27, 31, 35, 0.04)",
    "0px 2px 12px rgba(27, 31, 35, 0.12)",
    "0px 8px 20px rgba(27, 31, 35, 0.16)",
  ].concat(
    Array(21).fill("0px 32px 64px rgba(27, 31, 35, 0.24), 0px 2px 20px rgba(27, 31, 35, 0.16)"),
  ) as Theme["shadows"],
});
