import { Stack, Typography } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { queryClient } from "../../../../api/queryClient";
import { modelQueryKeys } from "../../../../api/useModels";
import { useUpload } from "../../../../api/useUpload";
import FilesStatusCard from "../../../../components/FilesStatusCard";
import FileStatusRow, { FileStatusRowProps } from "../../../../components/FileStatusRow/FileStatusRow";
import { AcceptableFileExtensions, FileUploadStatusType, HttpStatusCodeType } from "../../../../enums";
import { FilesStatusContext } from "../../../../lib/contexts/FilesStatusContext";
import { customColors } from "../../../../lib/utils/colors";

type FileStatusRowPropsExtended = FileStatusRowProps & {
  binary?: File;
  category: string;
  errorMsg: string;
  key: string;
  status: any;
};

function getErrorMessage(err: AxiosError) {
  if (err?.response?.status === HttpStatusCodeType.UnprocessableContent) {
    return `File type is not one of the supported types: ${Object.values(AcceptableFileExtensions).join(", ")}`;
  }
  return (err?.response as AxiosResponse)?.data.detail?.[0]?.msg || "An error occurred while uploading the file.";
}

function FilesStatusFloatingCard() {
  const auth = useAuth();
  const filesCtx = useContext(FilesStatusContext);
  const { t } = useTranslation();
  const uploadModel = useUpload();

  /**
   * File props object
   */
  const fileProps = useMemo(
    () => ({
      models: {
        uploadHandler: uploadModel.mutateAsync,
        // processHandler: getModelProgress.mutateAsync,
        // getPreviewPageURL: (fileId) => `${FULL_ROUTES_PATH.ROOT}${FULL_ROUTES_PATH.FILE}/${fileId}`,
      },
    }),
    // IMPORTANT: DO NOT ADD the react-query hooks handlers to the deps array
    [],
  );

  /**
   * Process file (models) handler function
   */
  const processFileHandler = useCallback(
    (file: File, id: string) => {
      // Update the original map (the map in the files context). Set the file's status to processing

      // Get the file from the files context
      // const fileData = filesCtx.getFile(id);

      filesCtx.updateFile(id, {
        status: FileUploadStatusType.Done,
        data: { progress: "completed" },
        errorMsg: "",
      });
    },
    // Important: Don't add the deleteFileById to the dependencies array
    [filesCtx, fileProps],
  );

  /**
   * Upload file handler function
   */
  const uploadFileHandler = useCallback(
    async (file: FileStatusRowPropsExtended, id: string) => {
      // Update the original map (the map in the files context). Set the file's status to uploading
      filesCtx.updateFile(id, { status: FileUploadStatusType.Uploading, errorMsg: "" });
      let data;
      try {
        // Upload the file
        data = await fileProps[file?.category as "models"]?.uploadHandler(file?.binary as File);

        if (data) {
          if (data.errorToS3) {
            filesCtx.updateFile(id, {
              status: FileUploadStatusType.UploadingError,
              errorMsg: "Uploading to cloud storage failed.",
            });
          }
          // Process the file - refresh the models list
          else if (!data.errorToS3 && data.processing_status === FileUploadStatusType.Created) {
            processFileHandler({ data } as any, id);
            queryClient.invalidateQueries(modelQueryKeys.all);
          }
        }
      } catch (err) {
        filesCtx.updateFile(id, {
          status: FileUploadStatusType.UploadingError,
          errorMsg: getErrorMessage(err as AxiosError),
        });
      }
    },
    [filesCtx, fileProps, processFileHandler],
  );

  /**
   * Render the files status rows
   */
  const renderFilesRows = useMemo(() => {
    const arrayOfFiles = Array.from(filesCtx.files);

    // If there are no files, render a message
    if (arrayOfFiles?.length === 0)
      return (
        <Stack justifyContent="center" alignItems="center" sx={{ p: "16px" }}>
          <Typography variant="body2" sx={{ color: customColors.gray[600] }}>
            {t("upload.statusCard.noFiles")}
          </Typography>
        </Stack>
      );

    return arrayOfFiles?.map(([id, file]) => (
      <FileStatusRow
        key={id}
        name={file?.binary?.name}
        errorMsg={file?.errorMsg}
        /* actionButton={getFileActionButton(id)} */
      />
    ));
  }, [filesCtx.files, t]); // getFileActionButton

  /**
   * Upload the newly added files
   */
  useEffect(() => {
    // Get the newly added files
    const newlyAddedFiles = new Map(
      Array.from(filesCtx.files).filter(([, file]) => file?.status === FileUploadStatusType.New),
    );

    // Upload the new files
    newlyAddedFiles.forEach((file, id) => {
      uploadFileHandler(file, id);
    });
    // Don't add the filesCtx.files as a dependency use only its size
  }, [filesCtx.files.size, uploadFileHandler]);

  /**
   * Process the newly uploaded files via URL
   */
  useEffect(() => {
    // Get the newly added files
    const newlyAddedFilesViaURL = new Map(
      Array.from(filesCtx.files).filter(([, file]) => file?.status === FileUploadStatusType.NewUrl),
    );

    // Process the new files
    newlyAddedFilesViaURL.forEach((file, id) => {
      processFileHandler(file, id);
    });
    // Don't add the filesCtx.files as a dependency, only use the Files Map size
  }, [filesCtx.files.size, processFileHandler]);

  if (!auth.isAuthenticated) {
    return null;
  }
  return (
    <FilesStatusCard
      show={filesCtx.showStatusCard}
      title={t("upload.statusCard.title")}
      totalFilesCount={filesCtx.files?.size}
      onDismiss={() => filesCtx.setShowStatusCard(false)}
    >
      {renderFilesRows}
    </FilesStatusCard>
  );
}

export default FilesStatusFloatingCard;
