/**
 * File upload status type enum.
 * Only CREATED is from FileService. Others are legacy from old file upload.
 * */

export enum FileUploadStatusType {
  Created = "CREATED",
  Done = "done",
  InvalidFileType = "invalidFileType",
  New = "new",
  NewUrl = "newUrl",
  NotSupported = "notSupported",
  Processing = "processing",
  ProcessingError = "processingError",
  Uploading = "uploading",
  UploadingError = "uploadingError",
}
