import { Button, ButtonProps, SvgIcon, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import { customColors, CustomColorsType } from "../../lib/utils/colors";

export const SIZES = {
  SMALL: 12,
  MEDIUM: 14,
  LARGE: 16,
} as const;

const TYPO_VARIANT = {
  16: "subtitle2b",
  14: "subtitle3b",
  12: "subtitle4b",
} as const;

const ICON_SIZE = {
  16: "1.5rem",
  14: "1.25rem",
  12: "1rem",
} as const;

interface IstariLinkProps extends Omit<ButtonProps, "size" | "color" | "endIcon" | "startIcon"> {
  href?: string;
  label: string;
  startIcon?: React.ElementType;
  endIcon?: React.ElementType;
  color?: keyof CustomColorsType;
  size?: (typeof SIZES)[keyof typeof SIZES];
  target?: string;
  underline?: boolean;
}
const IstariLink: React.FC<IstariLinkProps> = ({
  color = "primary",
  underline,
  href,
  onClick = () => {},
  startIcon,
  endIcon,
  size = SIZES.LARGE,
  label,
  disabled,
  sx,
  target,
  ...others
}) => {
  /**
   * Scroll to top of the page
   * Called only if href prop is passed
   */
  const scrollToTopHandler = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * onClick handler
   */
  const onClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <Button
      {...others}
      disabled={disabled}
      disableElevation
      disableRipple
      variant="text"
      sx={[
        {
          minWidth: "unset",
          borderRadius: "unset",
          bgcolor: "transparent",
          padding: 0,
          columnGap: "4px",
          alignItems: "center",
          color: customColors[color][color === "gray" ? 800 : 400],
          borderBottom: underline ? `1px solid ${customColors[disabled ? "gray" : color][100]}` : "none",
          fill: "currentcolor",
          "&:hover": {
            bgcolor: "transparent",
            color: customColors[color][color === "gray" ? 700 : 300],
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={href ? scrollToTopHandler : onClickHandler}
      component={href ? Link : "button"}
      target={target}
      to={href || undefined}
    >
      {startIcon && (
        <SvgIcon
          component={startIcon}
          className="istariLink_start-icon"
          inheritViewBox
          sx={{
            fontSize: ICON_SIZE[size],
          }}
        />
      )}

      <Typography variant={TYPO_VARIANT[size]}>{label}</Typography>

      {endIcon && (
        <SvgIcon
          component={endIcon}
          className="istariLink_end-icon"
          inheritViewBox
          sx={{ color: "inherit", fontSize: ICON_SIZE[size], fill: "inherit" }}
        />
      )}
    </Button>
  );
};

export default IstariLink;
