export function getEnv(key: string): string | undefined {
  // Load environment variables from the window object
  const windowEnv = (window as Window)?.env;

  // Check if the key exists in the window object
  if (windowEnv && windowEnv[key]) {
    return windowEnv[key];
  }

  // Otherwise return the key value from the import.meta.env object
  return import.meta.env[key];
}
